import "../../../styles/home.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/ReduxStore";
import { StoryConstants } from "../../../shared/StoryConstants";
import { useEffect } from "react";
import Config from "../../../Config";
import { RequestOptions } from "../../../tools/RequestOptions";
import { StatusSliceActions } from "../../../redux/status/StatusSlice";
import { Link } from "react-router-dom";

function TooEarly() {
  return (
    <div
      style={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        paddingBottom: "8rem",
        textAlign: "center",
      }}
    >
      <div>
        <h1>Qui êtes-vous ?</h1>
        <p style={{ marginTop: "1rem", fontSize: "1.1em" }}>
          Vous êtes libre de vous déconnecter dans les paramètres
        </p>
      </div>
    </div>
  );
}

function EndBlock({
  promoCode,
  fullOnWin,
}: {
  promoCode: string;
  fullOnWin: boolean;
}) {
  return (
    <div
      style={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        paddingBottom: "8rem",
        textAlign: "center",
      }}
    >
      <div
        style={{
          maxWidth: "800px",
        }}
      >
        <h1 style={{ marginBottom: "1.2rem" }}>Félicitations ?</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.35rem",
          }}
        >
          <p>
            Votre aide a permis à la Compagnie de démasquer les agents doubles
            et de remonter jusqu'à DANI, une source précieuse désormais tarie.
          </p>
          <p>Mais j'ai un moyen pour vous d'obtenir le pardon...</p>
          <p>
            Votre intervention me laissant sans aucune information de
            l'intérieur, considérez-vous comme mon agent double.
          </p>
          <p>
            Je vous recontacterais quand nous serons prêts à défier la
            Compagnie.
          </p>
        </div>
        <p
          style={{ marginTop: "1.2rem", fontSize: "1.1em", fontWeight: "bold" }}
        >
          HENRI
        </p>
        <div
          style={{
            marginTop: "2.4rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <p style={{ fontStyle: "italic" }}>
            Pour nous aider dans notre future mission, voici un{" "}
            <b>
              {fullOnWin
                ? "exemplaire gratuit"
                : "code promotionnel unique de 5€"}
            </b>{" "}
            pour transformer une de vos connaissances en agent de la Compagnie
            et lui faire vivre l'aventure
          </p>
          <Link
            className="button is-medium is-active"
            to={Config.stripe?.url + `?prefilled_promo_code=${promoCode}`}
          >
            Offrir le jeu à une nouvelle recrue
          </Link>
        </div>
      </div>
    </div>
  );
}

export function BodyFull() {
  const dispatch = useDispatch<AppDispatch>();
  const statusState = useSelector((state: RootState) => state.status);

  const isComplete =
    statusState.status &&
    statusState.status.step?.index &&
    ((statusState.status.story?.city === StoryConstants.City.LYON &&
      statusState.status.step?.index >= StoryConstants.Steps.Lyon.FIRST_END) ||
      (statusState.status.story?.city === StoryConstants.City.TOULOUSE &&
        [
          StoryConstants.Steps.Toulouse.GAME_END,
          StoryConstants.Steps.Toulouse.SECRET_FOUND,
        ].includes(statusState.status.step.index)) ||
      (statusState.status.story?.city === StoryConstants.City.PARIS &&
        [
          StoryConstants.Steps.Paris.GAME_END,
          StoryConstants.Steps.Paris.SECRET_FOUND,
        ].includes(statusState.status.step.index)));

  const goNextStep =
    isComplete &&
    statusState.status?.step &&
    ((statusState.status.story?.city === StoryConstants.City.LYON &&
      [
        StoryConstants.Steps.Lyon.FIRST_END,
        StoryConstants.Steps.Lyon.SECOND_LETTER,
      ].includes(statusState.status.step.index)) ||
      (statusState.status.story?.city === StoryConstants.City.TOULOUSE &&
        [StoryConstants.Steps.Toulouse.GAME_END].includes(
          statusState.status.step.index
        )) ||
      (statusState.status.story?.city === StoryConstants.City.PARIS &&
        [StoryConstants.Steps.Paris.GAME_END].includes(
          statusState.status.step.index
        )));

  useEffect(() => {
    if (goNextStep) {
      fetch(
        Config.endpoint + "user/status/nextStep",
        RequestOptions({
          method: "POST",
        })
      )
        .then(async (response) => {
          if (response.status === 200) {
            const status = await response.json();
            dispatch(StatusSliceActions.stepChanged(status));
          } else {
            console.error(response);
          }
        })
        .catch((e) => console.error(e));
    }
  }, [goNextStep, statusState.status, dispatch]);

  //Create Promo
  useEffect(() => {
    if (
      statusState.status?.step &&
      ((statusState.status.story?.city === StoryConstants.City.LYON &&
        statusState.status.step.index >= StoryConstants.Steps.Lyon.FIRST_END &&
        statusState.status.step.index <=
          StoryConstants.Steps.Lyon.SECRET_FOUND) ||
        (statusState.status.story?.city === StoryConstants.City.TOULOUSE &&
          statusState.status.step.index >=
            StoryConstants.Steps.Toulouse.GAME_END &&
          statusState.status.step.index <=
            StoryConstants.Steps.Toulouse.SECRET_FOUND) ||
        (statusState.status.story?.city === StoryConstants.City.PARIS &&
          statusState.status.step.index >=
            StoryConstants.Steps.Paris.GAME_END &&
          statusState.status.step.index <=
            StoryConstants.Steps.Paris.SECRET_FOUND))
    ) {
      fetch(
        Config.endpoint + "user/status/createPromo",
        RequestOptions({
          method: "POST",
        })
      )
        .then(async (response) => {
          if (response.status === 200) {
            console.log("Promotion code enabled");
          } else {
            console.error(response);
          }
        })
        .catch((e) => console.error(e));
    }
  }, [statusState.status]);

  return (
    <>
      {isComplete ? (
        <EndBlock
          promoCode={"P1" + statusState.status?.code}
          fullOnWin={
            statusState && statusState.status && statusState.status.fullOnWin
              ? true
              : false
          }
        />
      ) : (
        <TooEarly />
      )}
    </>
  );
}
