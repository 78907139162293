export type CityName = "lyon" | "paris" | "toulouse"

const CitiesNames: { [key: string]: CityName } = {
    LYON: "lyon",
    PARIS: "paris",
    TOULOUSE: "toulouse"
}

const StoriesIndexes = {
    FIRST_MISSION: 1
}

const StepsIndexes = {
    Lyon: {
        FIND_ALPHA: 1,
        FIND_STATUE: 2,
        DAME_PERCHEE: 3,
        RENDEZ_VOUS: 4,
        THE_SMS: 5,
        LA_PLACE: 6,
        DEVOIR_MUT: 7,
        STARS: 8,
        FIRST_END: 9,
        SECOND_LETTER: 10,
        SECRET_FOUND: 11,
    },
    Toulouse: {
        FIND_ALPHA: 1,
        FIND_FOUNTAIN: 2,
        TRINITE_TO_ASSEZAT: 3,
        ECHARPE_TABAC: 4,
        LE_PONT: 5, // classique
        PISTE_PHOTO: 6,
        FREQUENCES: 7, // recherche appel
        RECHERCHE_PASSERELLE: 8, // radio ?
        SQUARE_END: 9,
        GAME_END: 10,
        SECRET_FOUND: 11,
        ALTERNATIVE_PONT: 100,
    },
    Paris: {
        FIND_ALPHA: 1,
        PARC_FETE: 2,
        GO_BIBLI: 3,
        RECHERCHE_FREQUENCE: 4,
        GO_SQUARE: 5,
        ON_SQUARE: 6,
        WAY_BOLIVAR: 7,
        WAY_EQUERRE: 8,
        WAY_REBEVAL: 9,
        WAY_LAUZIN: 10,
        WAY_DUNES: 11,
        FONTAINE: 12,
        STAIRS_MESSAGES: 13,
        RECHERCHE_BANC: 14,
        GAME_END: 15,
        SECRET_FOUND: 16
    }
}

export const StoryConstants = {
    City: CitiesNames,
    Story: StoriesIndexes,
    Steps: StepsIndexes
}

export const StoryCodes = {
    SMS_VALIDATION: "ab85ss9*75",
    LYON_PLACE: "3763",
    TOULOUSE_FREQ_1: 60138,
    TOULOUSE_FREQ_2: 200598,
    PARIS_FREQ: 61101
}